@import './vars.scss';

html {
  font-family: 'Faktum-Regular', sans-serif;
  font-size: 62.5%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Faktum-Bold';
  font-display: auto;
  src: './fonts/faktum/Faktum-Bold.eot';
  src: url('./fonts/faktum/Faktum-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/faktum/Faktum-Bold.woff2') format('woff2'),
    url('./fonts/faktum/Faktum-Bold.woff') format('woff'),
    url('./fonts/faktum/Faktum-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Faktum-Medium';
  font-display: auto;
  src: './fonts/faktum/Faktum-Medium.eot';
  src: url('./fonts/faktum/Faktum-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/faktum/Faktum-Medium.woff2') format('woff2'),
    url('./fonts/faktum/Faktum-Medium.woff') format('woff'),
    url('./fonts/faktum/Faktum-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Faktum-Regular';
  font-display: auto;
  src: './fonts/faktum/Faktum-Regular.eot';
  src: url('./fonts/faktum/Faktum-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/faktum/Faktum-Regular.woff2') format('woff2'),
    url('./fonts/faktum/Faktum-Regular.woff') format('woff'),
    url('./fonts/faktum/Faktum-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Faktum-SemiBold';
  font-display: auto;
  src: './fonts/faktum/Faktum-SemiBold.eot';
  src: url('./fonts/faktum/Faktum-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/faktum/Faktum-SemiBold.woff2') format('woff2'),
    url('./fonts/faktum/Faktum-SemiBold.woff') format('woff'),
    url('./fonts/faktum/Faktum-SemiBold.ttf') format('truetype');
}
