@import 'styles/responsive.scss';
@import 'styles/vars.scss';
@import 'styles/mixins';

.body {
  &_default {
    font-size: $body_default_base;
    line-height: $body_default_base_lineheight;

    @include tablet {
      font-size: $body_default_tablet;
      line-height: $body_default_tablet_lineheight;
    }

    @include wide-screen {
      font-size: $body_default_widescreen;
      line-height: $body_default_widescreen_lineheight;
    }
  }

  &_big {
    font-size: $body_big_base;

    @include tablet {
      font-size: $body_big_tablet;
    }

    @include wide-screen {
      font-size: $body_big_widescreen;
    }
  }

  &_display {
    font-size: $body_big_base * 1.5;

    @include tablet {
      font-size: $body_big_tablet * 1.8;
    }

    @include wide-screen {
      font-size: $body_big_widescreen * 2.5;
    }
  }
}

.paragraph {
  margin: 2rem 0;
}

.headline {
  margin: 1rem 0 2rem 0;

  &_default {
    font-size: $headline_default_base;
    line-height: $headline_default_base_lineheight;
    letter-spacing: $headline_default_base_letterspacing;

    @include tablet {
      font-size: $headline_default_tablet;
      line-height: $headline_default_tablet_lineheight;
      letter-spacing: $headline_default_tablet_letterspacing;
    }

    @include wide-screen {
      font-size: $headline_default_widescreen;
      line-height: $headline_default_widescreen_lineheight;
      letter-spacing: $headline_default_widescreen_letterspacing;
    }
  }

  &_big {
    font-size: $headline_big_base;
    line-height: $headline_big_base_lineheight;
    letter-spacing: $headline_big_base_letterspacing;

    @include tablet {
      font-size: $headline_big_tablet;
      line-height: $headline_big_tablet_lineheight;
      letter-spacing: $headline_big_tablet_letterspacing;
    }

    @include wide-screen {
      font-size: $headline_big_widescreen;
      line-height: $headline_big_widescreen_lineheight;
      letter-spacing: $headline_big_widescreen_letterspacing;
    }
  }

  &_display {
    font-size: $headline_display_base;
    line-height: $headline_display_base_lineheight;
    letter-spacing: $headline_display_base_letterspacing;

    @include tablet {
      font-size: $headline_display_tablet;
      line-height: $headline_display_tablet_lineheight;
      letter-spacing: $headline_display_tablet_letterspacing;
    }

    @include wide-screen {
      font-size: $headline_display_widescreen;
      line-height: $headline_display_widescreen_lineheight;
      letter-spacing: $headline_display_widescreen_letterspacing;
      margin-left: -0.6rem;
    }
  }
}

.tagline {
  margin: 0;

  &_default {
    padding-left: 2.5rem;
    position: relative;
    &:before {
      content: '';
      @include center($vertical: true);
      left: 0;
      width: 2rem;
      height: 0.2rem;
      background: $black;
    }
  }

  &_horizontal {
    // position: absolute;
    left: 0;
    top: 0.2rem;
    max-width: 20%;

    @include tablet {
      top: 1rem;
    }
  }
}

.link {
  position: relative;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
}
