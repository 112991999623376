// @import-normalize;
@import 'styles/vars.scss';
@import 'styles/mixins.scss';

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

* {
  // cursor: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  box-sizing: border-box;
  &::selection {
    color: $white;
    background: $brandRed;
  }
}

html {
  font-family: 'Faktum-Regular', sans-serif;
  font-size: 62.5%;
  color: $black;
}

html,
body {
  overscroll-behavior: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

img {
  max-width: 100%;
}

hr {
  border: 0.1rem solid black;
}

ul {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

li {
  list-style-type: none;
}
