// Header Padding
$header_margin_vertical_mobile: 2rem;
$header_margin_vertical_tablet: 4rem;
$header_margin_vertical_widescreen: 8rem;

// Site padding
$site_horizontal_margin_mobile: 2rem;
$site_horizontal_margin_tablet: 5rem;

// Page Layout Padding
$layout_margin_mobile: $header_margin_vertical_mobile * 2 + 6
  $site_horizontal_margin_mobile $site_horizontal_margin_mobile
  $site_horizontal_margin_mobile;
$layout_margin_tablet: $header_margin_vertical_tablet * 2 + 7
  $site_horizontal_margin_tablet $site_horizontal_margin_tablet * 2
  $site_horizontal_margin_tablet;

// Content Max Width
$content_max_width: 165rem;

// Colors
$white: rgba(255, 255, 255, 1);
$black: rgba(0, 0, 0, 1);
$brandYellow: rgba(245, 199, 1, 1);
$brandPink: rgba(246, 206, 219, 1);
$brandRed: rgba(241, 109, 112, 1);
$brandTeal: rgba(136, 198, 219, 1);
$brandPurple: rgba(172, 160, 204, 1);
$brandGreen: rgba(189, 205, 203, 1);

// Fonts
$fontRegular: 'Faktum-Regular', sans-serif;
$fontMedium: 'Faktum-Medium', sans-serif;
$fontSemibold: 'Faktum-SemiBold', sans-serif;
$fontBold: 'Faktum-Bold', sans-serif;

// Typography Variables
// Default Body
$body_default_base: 1.8rem;
$body_default_base_lineheight: 2.5rem;
$body_default_tablet: 1.8rem;
$body_default_tablet_lineheight: 2.5rem;
$body_default_widescreen: 1.8rem;
$body_default_widescreen_lineheight: 2.5rem;

// Big Body
$body_big_base: 1.8rem;
$body_big_tablet: 2.4rem;
$body_big_widescreen: 2.8rem;

// Default Headline
// ... base
$headline_default_base: 2.2rem;
$headline_default_base_lineheight: 2.7rem;
$headline_default_base_letterspacing: 0;
// ... tablet
$headline_default_tablet: 2.2rem;
$headline_default_tablet_lineheight: 2.7rem;
$headline_default_tablet_letterspacing: -0.1rem;
// ... widescreen
$headline_default_widescreen: 2.2rem;
$headline_default_widescreen_lineheight: 2.7rem;
$headline_default_widescreen_letterspacing: -0.1rem;

// Big Headline
// ... base
$headline_big_base: 4rem;
$headline_big_base_lineheight: 4rem;
$headline_big_base_letterspacing: -0.1rem;
// ... tablet
$headline_big_tablet: 5.5rem;
$headline_big_tablet_lineheight: 5.5rem;
$headline_big_tablet_letterspacing: -0.1rem;
// ... widescreen
$headline_big_widescreen: 7rem;
$headline_big_widescreen_lineheight: 7rem;
$headline_big_widescreen_letterspacing: -0.1rem;

// Display Headline
// ... base
$headline_display_base: 8rem;
$headline_display_base_lineheight: 8rem;
$headline_display_base_letterspacing: -0.4rem;
// ... tablet
$headline_display_tablet: 15rem;
$headline_display_tablet_lineheight: 14rem;
$headline_display_tablet_letterspacing: -0.6rem;
// ... widescreen
$headline_display_widescreen: 18rem;
$headline_display_widescreen_lineheight: 16rem;
$headline_display_widescreen_letterspacing: -0.7rem;

:export {
  white: $white;
  black: $black;
  brandYellow: $brandYellow;
  brandPink: $brandPink;
  brandRed: $brandRed;
  brandTeal: $brandTeal;
  brandPurple: $brandPurple;
  brandGreen: $brandGreen;
  contentMaxWidth: $content_max_width;
}
